import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Buffer } from 'buffer'
import AWS from 'aws-sdk'
import Webcam from "react-webcam"
import { useAppDispatch } from '../../app/hooks';
import { Box, Theme, Typography } from '@mui/material'
import { makeStyles, createStyles } from "@mui/styles";
import theme from '../../theme/theme'
import CustomModal from '../modal/customModal'
import { loaderStart, loaderEnd } from '../../features/loader/loader.slice';
import { useNavigate } from 'react-router-dom';
import { Alert } from '../../utils';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flexColumn: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            // marginBottom:70,
        },
        flexRow: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        frContainer: {
            margin: "30px 20px 110px"
        },
    })
);


const videoConstraints = {
    width: 480,
    height: 480,
    facingMode: "user"
};

const FR = (props: any) => {
    const classes = useStyles({ theme });
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const {
        insuranceNumber,
        insuranceStatus,
        faceId,
        proceedToCall,
        entityId,
        patientName,
    } = props;
    const webcamRef = useRef(null);
    const frRef = useRef(null);
    const [capturedImage, setCapturedImage] = useState(null);
    const [processedImage, setProcessedImage] = useState(null);
    const [showVideo, setShowVideo] = useState(false);
    const [doNotAccept, setDoNotAccept] = useState(false);
    const [noFaceCaptured, setNoFaceCaptured] = useState(null);
    const [faceCaptured, setFaceCaptured] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);

    const client = new AWS.Rekognition({
        credentials: {
            accessKeyId: "AKIAZ445DRI3RP6S7776",
            secretAccessKey: "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",
        },
        region: "eu-west-2"
    });
    const s3Bucket = new AWS.S3({
        credentials: {
            accessKeyId: "AKIAZ445DRI3RP6S7776",
            secretAccessKey: "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",
        },
        region: "eu-west-2",
    })

    useEffect(() => {
        if (frRef.current) {
            frRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [frRef]);

    useEffect(() => {
        if (showVideo && timeLeft === 0) {
            capture();
        }
        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    useEffect(() => {
        if (showVideo && webcamRef.current) {
            webcamRef.current.video.scrollIntoView({ behavior: 'smooth' })
        }
    }, [showVideo, webcamRef]);

    useEffect(() => {
        if (capturedImage) {
            const imageToSend = capturedImage.split("data:image/png;base64,")[1];

            dispatch(loaderStart());
            client.searchFacesByImage({
                CollectionId: "fr-image-search-collection",
                FaceMatchThreshold: 70,
                Image: {
                    Bytes: Buffer.from(imageToSend, 'base64'),
                },
                MaxFaces: 1,
            }, function (err, data) {
                if (err) {
                    console.log(err.code, err.message); //, err.stack
                    dispatch(loaderEnd());
                    setNoFaceCaptured({
                        status: err.code,
                        message: err.message,
                    })
                } else {
                    console.log(data.FaceMatches)
                    dispatch(loaderEnd());
                    setProcessedImage({
                        faceId: data?.FaceMatches[0]?.Face?.FaceId,
                        imageId: data?.FaceMatches[0]?.Face?.ImageId,
                    });
                    setFaceCaptured(true);
                }
            })
        }
    }, [capturedImage]);

    useEffect(() => {
        if (processedImage && processedImage.faceId === faceId) {
            proceedToCall()
        }
    }, [processedImage])

    const capture = useCallback(
        () => {
            let imageSrc = webcamRef.current.getScreenshot();
            setCapturedImage(imageSrc);
        },
        [webcamRef]
    );

    return (
        <div ref={frRef} className={`${classes.frContainer} ${classes.flexColumn}`}>
            {insuranceStatus && faceId && !showVideo &&
                <Box ref={frRef}>
                    <Typography className='mb-20'>To authenticate your identity and match it to your insurance card, we need to capture your face biometric.</Typography>
                    <Box className={classes.flexRow}>
                        <button
                            className='btn btn-primary mr-20'
                            onClick={() => {
                                if (!props.phoneNumber) {
                                    Alert(2, "Please enter phone number");
                                    return
                                }
                                setShowVideo(true);
                                setTimeLeft(5);
                            }}
                        >
                            Accept
                        </button>
                        <button
                            className='btn btn-simple mr-20'
                            onClick={() => setDoNotAccept(true)}
                        >
                            Do not Accept
                        </button>
                        <button
                            className='btn btn-simple'
                            onClick={() => navigate("/")}
                        >
                            Cancel this Consultation
                        </button>
                    </Box>
                </Box>
            }

            {doNotAccept &&
                <CustomModal
                    content={
                        <Typography>
                            You can not proceed without authentication.
                        </Typography>
                    }
                    open={doNotAccept}
                    handleConfirm={() => setDoNotAccept(false)}
                    confirmButtonText={"OK"}
                    loading={false}
                />
            }

            {showVideo && !capturedImage &&
                <div className={classes.flexColumn}>
                    <div className={classes.flexRow}>
                        <Webcam
                            audio={false}
                            mirrored
                            height={480}
                            width={480}
                            ref={webcamRef}
                            screenshotFormat="image/png"
                            videoConstraints={videoConstraints}
                        />

                        <Box sx={{ margin: "20px", display: "flex", flexDirection: "column" }}>
                            <Typography variant='h5' sx={{ marginBottom: "10px" }}>
                                Look at the screen
                            </Typography>
                            <Typography>
                                {`Your face biometric will be captured in ${timeLeft}`}
                            </Typography>
                        </Box>
                        {/* <button
                            className='btn btn-primary m-20'
                            onClick={(e)=>{
                                e.preventDefault();
                                capture();
                            }}
                        >
                            Capture Face Biometric
                        </button> */}
                    </div>
                </div>
            }
            {capturedImage &&
                <div className={classes.flexRow}>
                    <img src={capturedImage} alt="" />
                    {!processedImage?.faceId && (
                        <button
                            className='btn btn-primary m-20'
                            onClick={() => {
                                setShowVideo(true);
                                setTimeLeft(5);
                                setCapturedImage(null);
                            }}
                        >
                            Retry
                        </button>
                    )}
                </div>
            }

            {faceCaptured &&
                <CustomModal
                    header={"Face Biometric Detection Result"}
                    content={
                        processedImage.faceId === faceId
                            ? <>
                                <Typography>
                                    {"Face biometric matched successfully!"}
                                </Typography>
                                <Typography sx={{ marginTop: '20px' }}>
                                    {"Connecting to your doctor now"}
                                </Typography>
                            </>
                            : <Typography>
                                {"Face biometric does not match! Please try again."}
                            </Typography>
                    }
                    open={faceCaptured}
                    hideConfirmButton={processedImage.faceId === faceId}
                    handleConfirm={() => {
                        setFaceCaptured(false);
                        setShowVideo(true);
                        setTimeLeft(5);
                        setCapturedImage(null);
                    }}
                    confirmButtonText={"Retry"}
                    loading={false}
                />
            }

            {noFaceCaptured &&
                <CustomModal
                    header="Face Biometric Detection Result"
                    content={
                        <Typography>
                            {"Face was not captured properly! Please try again."}
                        </Typography>
                    }
                    open={noFaceCaptured}
                    handleConfirm={() => {
                        setNoFaceCaptured(false);
                        setShowVideo(true);
                        setTimeLeft(5);
                        setCapturedImage(null);
                    }}
                    confirmButtonText={"Retry"}
                    loading={false}
                />
            }
        </div>
    );
};

export default FR